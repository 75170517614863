<template>
  <div class="column" v-loading="loading" element-loading-text="拼命加载中">
    <div class="header flex">
      <div
          @click="goRouter('/section/sectionDetail/', n.id)"
          v-for="(n, i) in doTopList"
          :key="i"
      >
        <div class="header_left relative cursorP" v-if="i == 0">
          <img :src="n.reamark1" :title="n.name" :alt="n.name"/>
          <div class="weimu">
            <p class="line_clamp1">{{ n.name }}</p>
          </div>
        </div>
      </div>
      <ul class="header_right flex-wrap">
        <li
            v-for="(item, i) in doTopList"
            :key="i"
            class="relative cursorP"
            v-if="i != 0"
            @click="goRouter('/section/sectionDetail/', item.id)"
        >
          <img :src="item.reamark1" :title="item.name" :alt="item.name"/>
          <div class="weimu">
            <p class="line_clamp1">{{ item.name }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="container">
      <div class="kol_banner" @click="goRouter('/section/activity')" v-show="isKol == 0">
        <img src="~/static/column/activity/kol_banner.png" alt="进入kol活动">
      </div>
      <div class="content container flex">
        <div class="column_left">
          <div class="tabs flex">
            <ul class="tab flex">
              <li
                  v-for="(item, index) in tab"
                  :class="{ active: active == index }"
                  :key="index"
                  @click="tabs(index)"
              >
                {{ item }}
              </li>
            </ul>
            <ul class="tab_child flex">
              <li :class="{ active: actives == 0 }" @click="tabsList(0)">
                全部
              </li>
              <li
                  v-for="(n, i) in tabList"
                  :class="{ active: actives == i + 1 }"
                  :key="i"
                  @click="tabsList(i + 1, n.value)"
              >
                {{ n.label }}
              </li>
            </ul>
            <button class="publish_btn" @click="openReamark(1)">发布文章</button>
          </div>
          <div class="containers" v-show="active == 0">
            <ul class="list" v-if="list">
              <li
                  class="box relative"
                  v-for="(item, index) in list"
                  :key="index"
              >
                <div class="info flex relative">
                  <router-link
                      class="avatar"
                      :to="'./sectionCenter?id=' + item.authoreid"
                      target="_blank"
                  >
                    <img :src="item.authorInfo.avatar" :alt="item.authorInfo.realname" class="err_avatar"/>
                  </router-link>
                  <router-link
                      :to="'/section/sectionCenter?id=' + item.authoreid"
                      :title="item.authorInfo.realname"
                      target="_blank"
                      class="name"
                  >
                    {{ item.authorInfo.realname }}
                    <p class="line_clamp2">{{ item.authorInfo.breifdescrition }}</p>
                  </router-link>
                  <div
                      class="attention"
                      @click="lock(item.authoreid, item.userInfo, 0)"
                  >
                    <el-button
                        :type="item.userInfo.focus == 0 ? 'primary' : 'info'"
                        :class="item.userInfo.focus == 0 ? 'primary' : 'info_btn'"
                        size="mini"
                        round
                    >
                      <span v-if="item.userInfo.focus == 0">
                        <i class="el-icon-plus" aria-hidden="true" ></i> 关注
                      </span>
                      <span v-else>已关注</span>
                    </el-button>
                  </div>
                </div>
                <h2 @click="goRouter('/section/sectionDetail/', item.id)">
                  <a href="javascript:void(0);" :title="item.name">
                    <img
                        src="~/static/column/top.png"
                        v-show="item.hasTop == 1"
                    />
                    {{ item.name }}
                  </a>
                </h2>
                <div class="text flex">
                  <viewer :images="[item.reamark1]" class="viewer">
                    <img
                        :src="item.reamark1"
                        :alt="item.name"
                        width="200"
                        :title="item.name"
                        class="text_image"
                    />
                  </viewer>
                  <span
                      @click="goRouter('/section/sectionDetail/', item.id)"
                      class="line_clamp3"
                  >{{ item.gaidesc }}</span
                  >
                </div>
                <div class="features" v-if="item.jsonObject">
                  <span>
                    <img src="~/static/ask/read.png" alt="阅读量"/>
                    {{ item.clicks }}阅读
                  </span>
                  <span>
                    <img src="~/static/ask/comments.png" alt="评论量"/>
                    {{ item.jsonObject.recomands }}评论
                  </span>
                  <span
                      class="praise"
                      @click="collectCloumn(item.id, item)"
                      :class="item.userInfo.goods == 0 ? '' : 'success'"
                  >
                    <img src="~/static/ask/zan.png" alt class="img"/>
                    <img src="~/static/ask/zan_green.png" alt="点赞量" class="green"/>
                    {{ item.jsonObject.goods }}点赞
                  </span>
                  <div class="type">#{{ item.fenclass }}</div>
                </div>
              </li>
            </ul>
            <div
                class="section_more"
                v-if="isMore"
                @click.stop="mores(0)"
                v-loading="loading"
            >
              点击加载更多
            </div>
          </div>
          <div class="containers" v-show="active == 1">
            <ul class="list">
              <li
                  class="box relative"
                  v-for="(item, index) in lists"
                  :key="index"
              >
                <div class="info flex relative" v-if="item.authorInfo">
                  <router-link
                      class="avatar"
                      :to="'/section/sectionCenter?id=' + item.authoreid"
                      target="_blank"
                  >
                    <img :src="item.authorInfo.avatar" alt class="err_avatar"/>
                  </router-link>
                  <router-link
                      :to="'/section/sectionCenter?id=' + item.authoreid"
                      :title="item.authorInfo.realname"
                      target="_blank"
                      class="name"
                  >
                    {{ item.authorInfo.realname }}
                    <p class="line_clamp2">{{ item.authorInfo.breifdescrition }}</p>
                  </router-link>
                </div>
                <h2 @click="goRouter('/section/sectionDetail', item.id)">
                  <a href="javascript:void(0);" :title="item.name">
                    <img
                        src="~/static/column/top.png"
                        alt
                        v-show="item.hasTop == 1"
                    />
                    {{ item.name }}
                  </a>
                </h2>
                <div class="text flex">
                  <viewer :images="[item.reamark1]" class="viewer">
                    <img
                        :src="item.reamark1"
                        alt
                        width="200"
                        :title="item.name"
                        class="text_image"
                    />
                  </viewer>
                  <span
                      @click="goRouter('/section/sectionDetail/', item.id)"
                      class="line_clamp3"
                  >{{ item.gaidesc }}</span
                  >
                </div>
                <div class="features" v-if="item.jsonObject">
                  <span>
                    <img src="~/static/ask/read.png" alt/>
                    {{ item.clicks }}阅读
                  </span>
                  <span>
                    <img src="~/static/ask/comments.png" alt/>
                    {{ item.jsonObject.recomands }}评论
                  </span>
                  <span
                      class="praise"
                      @click="collectCloumn(item.id, item)"
                      :class="item.userInfo.goods == 0 ? '' : 'success'"
                  >
                    <img src="~/static/ask/zan.png" alt class="img"/>
                    <img src="~/static/ask/zan_green.png" alt class="green"/>
                    {{ item.jsonObject.goods }}点赞
                  </span>
                  <div class="type">#{{ item.fenclass }}</div>
                </div>
              </li>
            </ul>
            <div
                class="section_more"
                v-if="isMores"
                @click.stop="mores(1)"
                v-loading="loading"
            >
              点击加载更多
            </div>
            <div class="author">
              <div class="tip" v-if="lists.length < 1">
                暂无记录，为您推荐以下作者
              </div>
              <ul class="author_list flex">
                <li
                    v-for="(n, i) in authorList"
                    :key="i"
                    @click="goRouter('./sectionCenter', n.id)"
                >
                  <img :src="n.avatar" alt class="avatar err_avatar"/>
                  <div class="name">{{ n.realname }}</div>
                  <p class="line_clamp2 text">{{ n.breifdescrition }}</p>
                  <div class="attention" @click.stop="lock(n.id, n, 1)">
                    <el-button
                        :type="n.userFocus ? 'info' : 'primary'"
                        :class="n.userFocus ? 'info_btn' : 'primary'"
                        size="mini"
                        round
                    >
                      <span v-if="!n.userFocus">
                        <i class="el-icon-plus"></i> 关注
                      </span>
                      <span v-else>已关注</span>
                    </el-button>
                  </div>
                </li>
              </ul>
              <div class="barter marB20" @click="attentionInfo">
                <span> <i class="el-icon-refresh"></i> 换一换 </span>
              </div>
            </div>
          </div>
        </div>
        <div class="column_right relative">
          <div class="title">
            <img src="~/static/column/hot.png" alt="最热" aria-hidden="true"/>
            最热
          </div>
          <ul class="list">
            <li
                v-for="(n, i) in hotList"
                :key="i"
                class="line_clamp1"
                @click="goRouter('/section/sectionDetail/', n.id)"
            >
              <a href="javascript:void(0);" :title="n.name">
                <span
                    :style="{
                    background:
                      i + 1 < 4
                        ? 'linear-gradient(90deg,rgba(253,118,89,1) 0%,rgba(243,35,46,1) 100%)'
                        : '',
                  }"
                >{{ i + 1 }}</span
                >
                {{ n.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="fixed">
      <img src="~/static/column/activity/fixed2.png" alt="kol活动" v-if="isKol == 1" @click="openReamark(1)">
      <img src="~/static/column/activity/fixed.png" alt="kol活动" v-else @click="openReamark(2)">
    </div>
    <!-- 弹窗 -->
    <el-dialog
        :title="isType?'文章发布':'KOL文章发布'"
        :visible.sync="dialogVisible"
        width="580px"
        :before-close="handleClose">
      <articleForm :type="isType" @close="handleClose"></articleForm>
    </el-dialog>
    <!-- KOL作者弹窗-->
    <el-dialog
        title="KOL作者报名"
        :visible.sync="isShow"
        width="580px"
        :before-close="handleClose">
      <kol-author-edit @close="handleClose"></kol-author-edit>
    </el-dialog>
    <login-prompt
        :goShow="isHide"
        @close="handleClose"
        title="登录"
        :text="title"
    ></login-prompt>
    <authentication :show=isAuthentication @close="handleClose"></authentication>
  </div>
</template>

<script>
import loginPrompt from "@/components/public/loginPrompt";
import kolAuthorEdit from "@/components/community/kolAuthorEdit";
import articleForm from "@/components/community/articleForm";
import authentication from "@/components/public/authentication";

export default {
  name: "index",
  metaInfo: {
    title: "专栏-兰州市残疾人就业创业网络服务平台",
    meta: [
      {
        name: "keywords",
        content: "专栏文章,残疾人文章 ,残疾人优秀作者",
      },
      {
        name: "description",
        content:
            "兰州市残疾人就业创业网络服务平台,专栏版块为残疾人搭建一个可读性较强的,汇集全国优秀的残疾人代表,通过文字、视频等方式向残疾人用户和企业用户展现专业性较强的有关残疾人就业创业相关的文字,旨在帮助残疾人和企业更好的了解残疾人就业相关政策和思路。",
      },
    ],
  },
  components: {
    loginPrompt, kolAuthorEdit, articleForm, authentication
  },
  data() {
    return {

      dialogVisible: false,
      active: 0,
      tab: ["推荐", "关注"],
      doTopList: [],
      list: [],
      hotList: [],
      authorList: [],
      lists: [],
      page: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
        fenclass: "",
      },
      actives: -1,
      tabList: [],
      attention: false,
      image: [],
      loading: false,
      isMore: false,
      isMores: false,
      isHide: false,
      isShow: false,
      isAuthentication: false,
      title: "登录之后才可以查看",
      isLogin: "",
      isKol: "0",
      isType: 0,
    };
  },
  watch: {
    $route() {
      this.info();
    },
    deep: true,
  },
  methods: {


    tabs(i) {
      if (this.isLogin) {
        this.active = i;
        if (this.active == 1) {
          this.attentionInfo();
          this.getAuthorArticle();
          this.title = "只有登录之后才可以查看";
        } else {
          this.info();
        }
      } else {
        this.isHide = true;
        this.active = 0;
      }
    },
    //默认数据
    info() {
      this.loading = true;
      this.$api
          .sectionListApi(this.page)
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              this.isMore = false;
              this.list = this.list.concat(res.data.data.records);
              this.isLogin =
                  this.$store.state.userType ||
                  JSON.parse(localStorage.getItem("userType"));
              this.page.total = res.data.data.total;
              if (this.isLogin) {
                this.kolAuthor();
              }
            }
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    //置顶、banner
    topList() {
      this.loading = true;
      this.$api
          .querySpcialClomunTopListApi()
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              this.doTopList = res.data.data.records;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    //作者列表
    attentionInfo() {
      this.loading = true;
      this.$api
          .getAuthorsApi()
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              this.authorList = res.data.data.records;
            }
          })
          .catch(() => {
            this.loading = false;
          });
    },
    //关注的文章列表
    getAuthorArticle() {
      this.loading = true;
      this.$api
          .getListApi(this.page)
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              this.isMores = false;
              this.lists = this.lists.concat(res.data.data.records);
              console.log(this.lists);
              this.page.total = res.data.data.total;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    //热门数据
    hotInfo() {
      this.$api
          .queryHotApi()
          .then((res) => {
            if (res.data.success) {
              this.hotList = res.data.data.records;
            }
          })
          .catch((er) => {
            this.loading = false;
          });
    },
    //分类
    selectList() {
      this.$api.selectApi({dictCode: "clum_class"}).then((res) => {
        if (res.data.success) {
          this.tabList = res.data.data;
        }
      });
    },
    //筛选项
    tabsList(i, val) {
      this.actives = i;
      this.page.pageNum = 1;
      this.page.fenclass = val;
      this.list = [];
      this.lists = [];
      this.info();
      if (this.active == 1) {
        this.getAuthorArticle();
      }
    },
    //发布文章前进行登陆判断
    openReamark(type) {
      console.log(type)
      if (this.isLogin) {
        if (this.isLogin.userType == 4) {
          this.isAuthentication = true;
          return
        }
        if (type == 1) {
          this.dialogVisible = true;
          this.isType = 0;
        } else {
          this.isShow = true;
        }
      } else {
        this.isHide = true;
      }
    },
    //关注
    lock(id, info, i) {
      if (this.isLogin) {
        this.$api.focusAuthorApi({id: id}).then((res) => {
          if (res.data.success) {
            if (i == 1) {
              info.userFocus = !info.userFocus;
              if (info.userFocus) {
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            } else {
              info.focus = 1 ^ info.focus;
              if (info.focus) {
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            }
          }
        });
      } else {
        this.title = "登录成功后才可已关注！";
        this.isHide = true;
      }
    },
    //点赞
    collectCloumn(id, info) {
      if (this.isLogin) {
        this.$api.collectCloumnApi({id: id}).then((res) => {
          var goods = Number(info.jsonObject.goods);
          info.userInfo.goods = 1 ^ info.userInfo.goods;
          if (info.userInfo.goods) {
            info.jsonObject.goods = Number(info.jsonObject.goods) + 1;
            this.$message.success(res.data.msg);
          } else {
            info.jsonObject.goods -= 1;
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.title = "登录成功后才可以点赞！";
        this.isHide = true;
      }
    },
    //点击加载
    mores(i) {
      this.page.pageNum += 1;
      if (i == 0) {
        this.info();
      } else {
        this.getAuthorArticle();
      }
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    //滚动下拉
    scrollBottom() {
      // 变量scrollTop为当前页面的滚动条纵坐标位置
      let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      // 变量 windowHeight 是可视区的高度
      let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      // 变量 scrollHeight 是滚动条的总高度
      let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollHeight - (scrollTop + windowHeight) < 350) {
        if (this.page.total != this.list.length) {
          this.isMore = true;
        } else {
          this.isMore = false;
        }
        if (this.page.total != this.lists.length) {
          this.isMores = true;
        } else {
          this.isMores = false;
        }
      }
    },
    //判断是否是KOL作者
    async kolAuthor() {
      let res = await this.$api.ifNotArticleApi();
      if (res.data.success) {
        this.isKol = res.data.data;
      }
    },
    handleClose() {
      this.isHide = false;
      this.dialogVisible = false;
      this.isShow = false;
      this.isAuthentication = false;
    },

  },
  created() {
    this.info();
    this.hotInfo();
    this.topList();
    this.selectList();
    //判断是否移动端
    this.$mobile("https://lzmz.cdpee.org.cn/#/pages/community/index");
  },
  mounted() {
    window.addEventListener("scroll", this.scrollBottom);
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/column.less";
/*通过fixed固定mescroll的高度*/
/deep/ .mescroll {
  height: auto;
}

/deep/ .el-form-item__error {
  color: #FF0000 !important;
}

.kol_banner {
  width: 1200px;
  margin: 0 auto;
  cursor: pointer;
}

.fixed {
  position: fixed;
  left: 94%;
  top: 70%;
  transform: translateY(-50%);

  img {
    cursor: pointer;
  }
}
</style>
